@use "./normalize";
@use "./css-reset";

$main-color: #000000;
$light-color: #5e5d5d;
$bright-color: #888888;

@font-face {
  font-family: soundBold;
  src: url('./assets/fonts/Interstate-Bold.ttf');
}
@font-face {
  font-family: sound;
  src: url('./assets/fonts/interstate-regular.ttf');
}

body {
  background-image: url('./assets/Hintergrund-mit-Rauschen-heller.jpg');
  background-repeat: repeat;
  color: $light-color;
  margin: 0;
  position: relative;
  .video-bg {
    background-image: url('./assets/Hintergrund-mit-Rauschen-neu.jpg');
    background-repeat: repeat;
    position: relative;
    /* NOT FOR IOS */
  }
  &:not(.detected) {
    .video-bg {
      &:before {
        content:'';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0,0,0,0.125);
      }
    }
  }
}
a {
  color: $main-color;
  text-decoration: none;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  &:hover, &:focus, &:active {
    color: $light-color;
    text-decoration: underline;
  }
}
strong {
  font-family: 'soundBold';
}

.logo-container {
  height: 140px;
  margin-bottom: 0px;
  padding-top: 50px;
  position: relative;
  text-align: center;
  z-index: 100;

  img {
    height: 140px;
    opacity: 1;
    width: auto;
  }

  @media screen and (min-width: 768px) {
    height: 150px;

    img {
      height: 150px;
    }
  }
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}

	70% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}

	70% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
	}
}

.container {
  margin: 0 auto;
  max-width: 100%;
  position: relative;

  .video-container {
    height: auto;
    overflow: hidden;
    background: transparent;
    position: relative;

    #videoBG {
      position: relative;
      top: 0;
      left: 0%;
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      height: 0;
      padding-top: 56.25%;

      #videoBG {
        position: absolute;
        top: 0;
        left: 0;
        right: unset;
        width: 100%;
        height: 100%;
      }
 
    }
  }

  &.crop-video {
    @media screen and (max-width: 767px) {
      .video-container {
        #videoBG {
          position: relative;
          left: unset;
          right: 25%;
          top: 0;
          width: 150%;
        }
      }
    }
  }
}

.main-text-teaser {
  margin-bottom: 20px;
  padding: 30px;
  position: relative;
  text-align: center;
  background-color: rgba(0, 0, 0, .25);
  color: $light-color;
  font-family: 'soundBold';
  font-size: 24px;
  margin-top: -6px;

  &.impressum {
    background-color: unset;
    color: $bright-color;
    font-family: 'sound';
    a {
      color: $light-color;
      &:hover, &:focus, &:active {
        color: $main-color;
      }
    }
  }

  @media screen and (min-width: 768px) {
    font-size: 30px;
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  z-index: 10;

  &__impress {
    margin: 0 auto;
    max-width: 1200px;
    padding: 30px 15px;
    text-align: left;

    h2 {
      margin-bottom: 10px;
    }
    p {
      font-size: 22px;
    }
    .impress {
      h2 {
        font-family: 'soundBold';
      }
      padding-bottom: 30px;
    }
    .policy {
      p {
        font-family: "sound";
      }
      h1, h2, h3, h4 {
        font-family: 'soundBold';
      }
      h3 {
        font-size: 24px;
        margin-bottom: 8px;
      }
      h4 {
        font-size: 22px;
        margin-bottom: 5px;
      }
      ul {
        list-style-type: disc;
        margin-bottom: 10px;
        margin-left: 1em;

        li {
          font-family: "sound";
          font-size: 22px;
        }
      }
    }
  }
}

.footer {
  color: $main-color;
  font-family: 'sound';
  font-size: 22px;
  text-align: center;

  &__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 40px 20px;
    a {
      margin: 0 10px;

      svg {
        height: 45px;
        width: 45px;
          path {
          fill: $main-color;
          -webkit-transition: all 0.4s ease 0s;
          transition: all 0.4s ease 0s;
        }
      }
      &:hover, &:focus, &:active {
        path {
          fill: $light-color;
        }
      }
    }
  }
  &__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 40px 20px;
    img {
      width: 80px;
      height: 90px;
    }
  }
  &__address {
    margin: 40px 20px;
  }
  &__contact {
    margin: 40px 20px;
  }
  &__nav {
    padding-bottom: 60px;
    a {
      color: $main-color;
      text-decoration: none;
      -webkit-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s;
      &:hover, &:focus, &:active {
        color: $light-color;
        text-decoration: underline;
      }
    }
  }
}
